import React from "react";
import styled from "styled-components";
import { theme } from "../../lib/theme";

const Circle = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  flex-shrink: 0;
  background: url("/avatar.png");
  background-size: cover;
  background-position: center center;

  @media (max-width: 32em) {
    margin-bottom: 0.8rem;
  }
`;

const StyledQuote = styled.div`
  width: calc(100% - 4rem);
  max-width: 78rem;
  display: flex;
  align-items: center;
  background: ${theme.color.white[100]};
  box-shadow: 0px 4px 20px rgba(3, 3, 1, 0.06);
  border-radius: 1.6rem;
  padding: 2.4rem 3rem;

  @media (max-width: 32em) {
    max-width: 100%;
    padding: 2.4rem 1.6rem;
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-style: italic;
  color: ${theme.color.dark[50]};
  margin-left: 2.4rem;
  max-width: 52rem;

  @media (max-width: 32em) {
    margin-left: 0;
    text-align: center;
  }
`;

const Quote = ({ className }: { className?: string }) => (
  <StyledQuote className={className}>
    <Circle />
    <Text>
      “It is not the strongest of the species that survives, nor the most
      intelligent that survives. It is the one that is most adaptable to
      change.” - C.Darwin
    </Text>
  </StyledQuote>
);

export default Quote;
