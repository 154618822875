import React from "react";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "./Button";
import Logo, { StyledLogo } from "./Logo";
import Paragraph, { SIZES } from "./Paragraph";
import { theme } from "./theme";
import { Wrapper } from "./Wrapper";

const Text = styled(Paragraph)`
  color: ${theme.color.white[100]};
`;

const StyledHeader = styled.div<{ sticky?: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  animation: moveUp 0.5s ease-in-out;

  @media (max-width: 32em) {
    display: none;
  }

  ${(p) =>
    p.sticky &&
    css`
      height: 108px;
      background: ${theme.color.white[100]};
      box-shadow: 0px 4px 20px rgba(3, 3, 1, 0.06);
      position: fixed;
      animation: moveDown 0.5s ease-in-out;
      z-index: 9999;

      ${StyledLogo} {
        color: ${theme.color.dark[100]};
      }

      ${Text} {
        color: ${theme.color.dark[100]} !important;
      }

      ${Button} {
        color: ${theme.color.white[100]};
        background: ${theme.color.dark[100]};
      }
    `}

  @keyframes moveDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10.8rem;
`;

const Menu = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
`;

const MenuItem = styled.li`
  letter-spacing: 0.01rem;
  margin-left: 4rem;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 9999;

  @media (min-width: 32em) {
    display: none;
  }
`;

const MenuButton = styled.img`
  height: 2.4rem;
  display: block;
  cursor: pointer;
`;

const MobileNavigation = styled.div`
  width: 100%;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999999;
  position: relative;
`;

const MobileNavigationContent = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-top: 8.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.dark[100]};
  background-image: url("/BG@2x.png");
  background-position: 50% 50%;
  background-size: cover;
`;

const MobileMenu = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileMenuItem = styled.li`
  width: 100%;
  margin-bottom: 3.2rem;
  text-align: center;

  ${Button} {
    width: 100%;
  }
`;

const Header = ({ sticky }) => {
  const [menu, setMenu] = useState(false);

  const handleCloseMenu = () => {
    setMenu(false);
    const layout = document.getElementById("layout");
    layout.style.overflow = "visible";
    layout.style.height = "auto";
  };

  const handleOpenMenu = () => {
    setMenu(true);
    const layout = document.getElementById("layout");
    layout.style.overflow = "hidden";
    layout.style.height = "100vh";
  };

  return (
    <React.Fragment>
      <StyledHeader sticky={sticky}>
        <Wrapper>
          <Navigation>
            <Logo />
            <Menu>
              <MenuItem
                onClick={() => {
                  const intro = document.getElementById("intro");
                  intro.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <Text size={SIZES.M}>Home</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const about = document.getElementById("about");
                  about.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <Text size={SIZES.M}>How it works</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  const how = document.getElementById("how");
                  how.scrollIntoView({ behavior: "smooth" });
                }}
              >
                <Text size={SIZES.M}>What Darwin offers</Text>
              </MenuItem>
              <MenuItem>
                <Button secondary>Sign in</Button>
              </MenuItem>
            </Menu>
          </Navigation>
        </Wrapper>
      </StyledHeader>
      <MobileHeader>
        <Wrapper>
          <MobileNavigation>
            <Logo />
            {!menu && <MenuButton src={"/menu.png"} onClick={handleOpenMenu} />}
            {menu && (
              <MenuButton src={"/close.png"} onClick={handleCloseMenu} />
            )}
          </MobileNavigation>
          {menu && (
            <MobileNavigationContent>
              <MobileMenu>
                <MobileMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    const intro = document.getElementById("intro");
                    intro.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <Text size={SIZES.M}>Home</Text>
                </MobileMenuItem>
                <MobileMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    const about = document.getElementById("about");
                    about.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <Text size={SIZES.M}>How it works</Text>
                </MobileMenuItem>
                <MobileMenuItem
                  onClick={() => {
                    handleCloseMenu();
                    const how = document.getElementById("how");
                    how.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  <Text size={SIZES.M}>What Darwin offers</Text>
                </MobileMenuItem>
                <MobileMenuItem>
                  <Button secondary>Log in</Button>
                </MobileMenuItem>
              </MobileMenu>
            </MobileNavigationContent>
          )}
        </Wrapper>
      </MobileHeader>
    </React.Fragment>
  );
};

export default Header;
