import React from "react";
import Reveal from "react-reveal/Reveal";
import styled from "styled-components";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import Title, { HEADINGS } from "../../lib/TItle";
import { Wrapper } from "../../lib/Wrapper";

const StyledPage = styled(Page)`
  padding: 12rem 0;

  @media (max-width: 32em) {
    padding: 18rem 0 8rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 32em) {
    height: auto;
    flex-direction: column;
  }
`;

const Content = styled.div`
  max-width: 58rem;
  padding-right: 2rem;

  @media (max-width: 32em) {
    margin-bottom: 2rem;
  }
`;

const StyledTitle = styled(Title)`
  padding-right: 2.4rem;
  margin-bottom: 1.6rem;

  @media (max-width: 32em) {
    padding-right: 0;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  position: relative;
  color: ${theme.color.dark[100]};
  padding-left: 2rem;
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${theme.color.dark[100]};
    border-radius: 50%;
  }
`;

const BulletPoint = styled(Paragraph)`
  font-weight: 600;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 58rem;
  display: block;
  height: auto;
  flex-shrink: 0;
  box-shadow: 0px 4px 20px rgba(3, 3, 1, 0.06);
`;

const About = () => {
  return (
    <StyledPage id="about">
      <Wrapper>
        <Container>
          <Reveal effect="fadeIn">
            <Content>
              <StyledTitle size={HEADINGS.H3}>
                VC market becomes more and more Darwinian
              </StyledTitle>
              <List>
                <ListItem>
                  <BulletPoint size={SIZES.M}>
                    More and more startups
                  </BulletPoint>
                </ListItem>
                <ListItem>
                  <BulletPoint size={SIZES.M}>
                    More and more investors
                  </BulletPoint>
                </ListItem>
                <ListItem>
                  <BulletPoint size={SIZES.M}>
                    Only some more of the best startups
                  </BulletPoint>
                </ListItem>
              </List>
            </Content>
          </Reveal>
          <Reveal effect="fadeIn">
            <StyledImage src={"/data.png"} />
          </Reveal>
        </Container>
      </Wrapper>
    </StyledPage>
  );
};

export default About;
