import React from "react";
import Reveal from "react-reveal/Reveal";
import styled from "styled-components";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import Title, { HEADINGS } from "../../lib/TItle";
import { Wrapper } from "../../lib/Wrapper";

const StyledPage = styled(Page)`
  padding: 8rem 0 15rem;

  @media (max-width: 32em) {
    padding: 8rem 0;
  }
`;

const Container = styled.div`
  position: relative;
`;

const StyledTitle = styled(Title)`
  color: ${theme.color.dark[100]};
  margin-bottom: 0.8rem;
`;

const Text = styled(Paragraph)`
  font-size: 1.8rem;
  line-height: 2.7rem;
  margin-bottom: 4rem;

  @media (max-width: 32em) {
    margin-bottom: 1.2rem;
  }
`;

const StyledIntegrations = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;

  @media (max-width: 32em) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1.6rem;
    justify-content: center;
  }
`;

const Item = styled.div<{ imageUrl: string }>`
  background-color: ${theme.color.white[100]};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  border-radius: 0.9rem;
  width: 100%;
  height: 10.4rem;
  background-image: url(${(p) => p.imageUrl});

  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 32em) {
    width: 100%;
  }

  /* TODO: change those images... */

  &:nth-child(1) {
    background-size: 198px;

    @media (max-width: 32em) {
      background-size: 140px;
    }
  }

  &:nth-child(2) {
    background-size: 162px;

    @media (max-width: 32em) {
      background-size: 118px;
    }
  }

  &:nth-child(3) {
    background-size: 136px;

    @media (max-width: 32em) {
      background-size: 94px;
    }
  }

  &:nth-child(4) {
    background-size: 177px;

    @media (max-width: 32em) {
      background-size: 88px;
    }
  }
`;

const Integrations = () => {
  return (
    <StyledPage>
      <Wrapper>
        <Reveal effect="fadeIn">
          <Container>
            <StyledTitle size={HEADINGS.H3}>Our integrations</StyledTitle>
            <Text size={SIZES.M}>Some extra convincing copy would go here</Text>
            <StyledIntegrations>
              <Item imageUrl={"/logo-01.png"} />
              <Item imageUrl={"/logo-02.png"} />
              <Item imageUrl={"/logo-03.png"} />
              <Item imageUrl={"/logo-04.png"} />
            </StyledIntegrations>
          </Container>
        </Reveal>
      </Wrapper>
    </StyledPage>
  );
};

export default Integrations;
