import React from "react";
import Reveal from "react-reveal/Reveal";
import styled from "styled-components";
import { Button } from "../../lib/Button";
import Logo from "../../lib/Logo";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import Title, { HEADINGS } from "../../lib/TItle";
import { Wrapper } from "../../lib/Wrapper";

const StyledPage = styled(Page)`
  background-color: ${theme.color.dark[100]};
  background-image: url("/BG@2x.png");
  background-size: 90rem;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding: 0;

  @media (max-width: 32em) {
    background-position: 0% 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 0;
  border-bottom: 1px solid ${theme.color.white[10]};

  @media (max-width: 32em) {
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem 0 2.4rem;
  }
`;

const Content = styled.div`
  position: relative;
`;

const StyledTitle = styled(Title)`
  color: ${theme.color.white[100]};
  margin-bottom: 0.8rem;
`;

const Text = styled(Paragraph)`
  color: ${theme.color.white[100]};

  @media (max-width: 32em) {
    margin-bottom: 2.4rem;
  }
`;

const Section = styled.div`
  display: flex;
  padding: 8rem 0 14rem;

  @media (max-width: 32em) {
    flex-direction: column;
    padding: 4rem 0 9rem;
  }
`;

const CustomLogo = styled(Logo)`
  align-self: flex-start;

  @media (max-width: 32em) {
    margin-bottom: 3.2rem;
  }
`;

const Menus = styled.div`
  display: flex;
  margin-left: 10rem;

  @media (max-width: 32em) {
    margin-left: 0;
  }
`;

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 15rem;

  @media (max-width: 32em) {
    margin-right: 2rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const MenuHeading = styled.h4`
  font-size: 1.4rem;
  line-height: 2.7rem;
  color: ${theme.color.white[100]};
  margin-bottom: 1.2rem;
`;

const MenuItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: ${theme.color.white[100]};
  font-size: 1.3rem;
  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Footer = () => (
  <StyledPage>
    <Wrapper>
      <Reveal effect="fadeIn">
        <Container>
          <Content>
            <StyledTitle size={HEADINGS.H3}>
              Like what you’re seeing?
            </StyledTitle>
            <Text size={SIZES.M}>
              Let’s get in touch and schedule you a demo
            </Text>
          </Content>
          <Button>Request a demo</Button>
        </Container>
      </Reveal>
      <Section>
        <Reveal effect="fadeIn">
          <CustomLogo />
          <Menus>
            <Menu>
              <MenuHeading>Site</MenuHeading>
              <MenuItem>Back to top</MenuItem>
              <MenuItem>How it works</MenuItem>
              <MenuItem>What darwin.ai offers</MenuItem>
              <MenuItem>Integrations</MenuItem>
            </Menu>
            <Menu>
              <MenuHeading>Darwin.ai</MenuHeading>
              <MenuItem>Abramowice Prywatne 64A</MenuItem>
              <MenuItem>Lublin, Poland</MenuItem>
              <MenuItem>darwin@darwin.co</MenuItem>
              <MenuItem>+48 690 243 894</MenuItem>
            </Menu>
          </Menus>
        </Reveal>
      </Section>
    </Wrapper>
  </StyledPage>
);

export default Footer;
