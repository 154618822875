import React from "react";
import useSticky from "../hooks/useSticky";
import Header from "../lib/Header";
import Layout from "../lib/Layout";
import About from "../sections/About";
import Footer from "../sections/Footer";
import How from "../sections/How";
import Integrations from "../sections/Integrations";
import Intro from "../sections/Intro";
import Sponsors from "../sections/Sponsors";

const Site = () => {
  const { isSticky } = useSticky();

  return (
    <Layout>
      <Header sticky={isSticky} />
      <Intro />
      <About />
      <How />
      <Integrations />
      <Footer />
      <Sponsors />
    </Layout>
  );
};

export default Site;
