import { useEffect, useState, useRef } from "react";

function useSticky() {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setSticky(false);
    }

    if (window.scrollY > 300) {
      setSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [handleScroll]);

  return { isSticky };
}

export default useSticky;
