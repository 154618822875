import React from "react";
import styled from "styled-components";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import { Wrapper } from "../../lib/Wrapper";
import Reveal from "react-reveal/Reveal";

const StyledPage = styled(Page)`
  background: ${theme.color.white[100]};
  padding: 2.4rem 0;

  @media (max-width: 32em) {
    padding: 1.6rem 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 32em) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Text = styled(Paragraph)`
  color: ${theme.color.dark[100]};
  margin-right: 6.6rem;

  @media (max-width: 32em) {
    font-size: 1.3rem;
    line-height: 1.9rem;
    margin-bottom: 1.6rem;
    max-width: 18.5rem;
  }
`;

const Image = styled.img`
  display: block;
  height: 7.5rem;

  @media (max-width: 32em) {
    width: 100%;
    height: auto;
  }
`;

const Sponsors = () => (
  <StyledPage>
    <Wrapper>
      <Reveal effect="fadeIn">
        <Container>
          <Text size={SIZES.M}>
            This website is a beneficiary of European Funds
          </Text>
          <Image src={"/funds.png"} />
        </Container>
      </Reveal>
    </Wrapper>
  </StyledPage>
);

export default Sponsors;
