import React from "react";
import styled from "styled-components";
import { theme } from "./theme";

export const StyledLogo = styled.a`
  font-weight: 900;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${theme.color.white[100]};
`;

const Symbol = styled.span`
  color: ${theme.color.blue[100]};
`;

const Logo = ({ className }: { className?: string }) => (
  <StyledLogo className={className}>
    darwin<Symbol>.</Symbol>ai
  </StyledLogo>
);

export default Logo;
