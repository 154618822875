import React from "react";
import Reveal from "react-reveal/Reveal";
import styled from "styled-components";
import { Button } from "../../lib/Button";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import Title, { HEADINGS } from "../../lib/TItle";
import { Wrapper } from "../../lib/Wrapper";
import Quote from "./Quote";

const StyledPage = styled(Page)`
  position: relative;
  width: 100%;
  height: 85rem;
  background-color: ${theme.color.dark[100]};
  background-image: url("/BG@2x.png");
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0;

  @media (max-width: 32em) {
    height: 812px;
  }
`;

const Headline = styled.span`
  font-size: 1.3rem;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  color: ${theme.color.white[100]};
  opacity: 0.6;
  margin-bottom: 0.8rem;
`;

const Heading = styled(Title)`
  text-align: center;
  letter-spacing: 0.01em;
  color: ${theme.color.white[100]};
  margin-bottom: 1.6rem;

  @media (max-width: 37.5rem) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
`;

const Subheading = styled(Paragraph)`
  text-align: center;
  letter-spacing: 0.01em;
  color: ${theme.color.white[100]};
  margin: 0 auto 3.2rem;
  max-width: 56rem;
`;

const Container = styled.div`
  width: 100%;
  max-width: 69rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const RequestBtn = styled(Button)`
  align-self: center;
`;

const StyledQuote = styled(Quote)`
  position: absolute;
  bottom: -6.4rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 32em) {
    bottom: -11rem;
  }
`;

const Intro = () => {
  return (
    <StyledPage id="intro">
      <Wrapper>
        <Reveal effect="fadeIn">
          <Container>
            <Headline>VC’s Most advanced tool</Headline>
            <Heading size={HEADINGS.H1}>
              Track & filter best projects, support like never before
            </Heading>
            <Subheading size={SIZES.M}>
              Identify startups and help them identify candidates by connecting
              your fund’s, investors’ & portfolio’s network in one single tool.
            </Subheading>
            <RequestBtn>Request a demo</RequestBtn>
          </Container>
        </Reveal>
      </Wrapper>
      <StyledQuote />
    </StyledPage>
  );
};

export default Intro;
