import React from "react";
import styled, { css } from "styled-components";
import Page from "../../lib/Page";
import Paragraph, { SIZES } from "../../lib/Paragraph";
import { theme } from "../../lib/theme";
import Title, { HEADINGS } from "../../lib/TItle";
import { Wrapper } from "../../lib/Wrapper";
import Reveal from "react-reveal/Reveal";

const StyledPage = styled(Page)`
  background-color: ${theme.color.dark[100]};
`;

const PageTitle = styled(Title)`
  color: ${theme.color.white[100]};
  margin-bottom: 8rem;

  @media (max-width: 32em) {
    text-align: center;
    margin-bottom: 6.4rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 16rem;

  @media (max-width: 32em) {
    grid-row-gap: 4rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #020013;

  @media (max-width: 32em) {
    flex-direction: column;
  }
`;

const Box = styled.div<{ changeOrder?: boolean }>`
  padding: 3.2rem;
  max-width: 58rem;
  background: radial-gradient(
    100.14% 100% at 100% 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border-radius: 3.2rem;
  min-height: 33.4rem;

  @media (max-width: 32em) {
    padding: 2.4rem;
  }

  ${(p) =>
    p.changeOrder &&
    css`
      order: 1;

      @media (max-width: 32em) {
        order: 0;
      }
    `}
`;

const BoxTitle = styled(Title)`
  color: ${theme.color.white[100]};
  margin-bottom: 0.4rem;
`;

const Text = styled(Paragraph)`
  color: ${theme.color.white[100]};
  line-height: 2.7rem;
  margin-bottom: 2.4rem;
`;

const StyledImage = styled.img`
  display: block;
  width: 58rem;
  height: auto;
  flex-shrink: 0;

  @media (max-width: 32em) {
    width: 100%;
    margin-top: 4rem;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${theme.color.white[100]};
    border-radius: 50%;
  }
`;

const BulletPoint = styled(Paragraph)`
  font-weight: 600;
  color: #fff;
`;

const How = () => {
  return (
    <StyledPage id="how">
      <Wrapper>
        <Reveal effect="fadeIn">
          <PageTitle size={HEADINGS.H2}>How it works</PageTitle>
        </Reveal>
        <Grid>
          <Container>
            <Box>
              <Reveal effect="fadeIn">
                <BoxTitle size={HEADINGS.H3}>Track them all</BoxTitle>
                <Text size={SIZES.M}>
                  Darwin’s Tracker extract data from millions of data points to
                  give you most relevant info about companies you are interested
                  in.
                </Text>
                <List>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>Increase coverage</BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>Identify earlier</BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>Stay informed</BulletPoint>
                  </ListItem>
                </List>
              </Reveal>
            </Box>
            <Reveal effect="fadeIn">
              <StyledImage src={"/radar.svg"} />
            </Reveal>
          </Container>
          <Container>
            <Box changeOrder>
              <Reveal effect="fadeIn">
                <BoxTitle size={HEADINGS.H3}>Filter the best</BoxTitle>
                <Text size={SIZES.M}>
                  Darwin’s Recommender will help you score your dealflow and
                  filter most promising opportunities.
                </Text>
                <List>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Stop analyzing with incorrect data.
                    </BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Save time on due-dilligence.
                    </BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Let algorithms help you make better decisions faster.
                    </BulletPoint>
                  </ListItem>
                </List>
              </Reveal>
            </Box>
            <Reveal effect="fadeIn">
              <StyledImage src={"/funnel.svg"} />
            </Reveal>
          </Container>
          <Container>
            <Box>
              <Reveal effect="fadeIn">
                <BoxTitle size={HEADINGS.H3}>
                  Support like never before
                </BoxTitle>
                <Text size={SIZES.M}>
                  Darwin’s Networker will let you connect all your investment
                  Team’s, LPs and portfolio companies’ network in one single
                  tool.
                </Text>
                <List>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Maximize potential of your professional network.
                    </BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Build cooperation between portfolio companies.
                    </BulletPoint>
                  </ListItem>
                  <ListItem>
                    <BulletPoint size={SIZES.M}>
                      Convince more great founders to join you.
                    </BulletPoint>
                  </ListItem>
                </List>
              </Reveal>
            </Box>
            <Reveal effect="fadeIn">
              <StyledImage src={"/network.svg"} />
            </Reveal>
          </Container>
        </Grid>
      </Wrapper>
    </StyledPage>
  );
};

export default How;
